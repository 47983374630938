import request from './request'

const api = {
	// 列表
	lists: '/user/User/getPageList',
	// 删除
	dele: '/user/User/delete',
}

export {
	request,
	api
}
